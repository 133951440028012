<template>
  <div class="page_bg Order_bg">
    <!-- 顶部 card-->
    <div class="subOrder_bg_top">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name">提交订单</div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 地址选择 -->
    <div class="order_address_box flex-r-s-c"
         :style="{ minHeight : order_info.address.name? '2.3rem':'1.5rem'}"
         @click="changeAddress(order_info.address.id)"
    >
      <van-image width="0.69rem" height="0.69rem" src="https://oss.xiaoyi120.com/shop2.0/order/address_icon.png"
                 style="margin-left: 0.3rem;"
      />
      <div class="order_address_msg flex-c-s-s" style="margin-left: 0.3rem;">
        <div class="address_msg_name">{{ order_info.address.name || '还没选择地址，' }}
          {{ order_info.address.tel || '添加一个吧！' }}
        </div>
        <div v-if="order_info.address.name" class="address_msg_addres van-multi-ellipsis--l2">
          地址：{{ order_info.address.address }}
        </div>
      </div>
      <div class="order_address_change flex-r-c-c" style="margin-left: 0.3rem;">
        <span v-if="order_info.address.name">{{ order_info.address.tel ? '修改':'新增' }}</span>
        <span v-else>&nbsp;</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- 商品区域 -->
    <!-- <div class="order_pro_box" :style="{ marginTop : order_info.address.name? '-1.52rem':'-2.3rem' }"> -->
    <div class="order_pro_box" style="margin-top: 0.21rem;">
      <div class="cart_company" style="margin-bottom:0;width:9.26rem !important;">
        <div class="company_name flex-r-s-c" style="padding-left:0.15rem">
          <van-icon name="shop-o" />兽医首选
        </div>
        <div class="company_pro_list">
          <div v-for="(pro_item,pro_index) in order_info.companies[0].products" :key="pro_index" class="flex-r-s-c">
            <van-swipe-cell>
              <proCard04 ref="proCard04" :info="pro_item" :type="type" :flag="flag" :cannum="order_info.canBuyNum"
                         @changeNum="changeNum"
              />
              <!-- 优惠信息 -->
              <div v-if="pro_item.resActicityDtos" class="pro_discount_box ">
                <div class="pro_discount_items ">
                  <div v-for="(discounts,discounts_index) in pro_item.resActicityDtos" :key="discounts_index"
                       class="pro_discount_item flex-r-s-s"
                  >
                    <div class="discounts_tag" style="padding-right:0">{{ discounts.actionName }}：</div>
                    <div v-for="(discount,discount_index) in discounts.actions" :key="discount_index"
                         class="discounts_tag van-ellipsis" style="padding-left:0"
                    >
                      {{ discount }}
                    </div>
                  </div>
                </div>
              </div>
            </van-swipe-cell>
          </div>

        </div>
      </div>
      <!-- 订单赠品区域 -->
      <div v-if="giftList.length > 0" class="order_zeng_box" @click="gift_show = true">
        <van-cell>
          <template #title>
            <span class="custom-title">赠品：</span>
          </template>
          <div v-for="(gift,gift_index) in giftList" :key="gift_index" class="order_zeng_box_pic">
            <div class="order_zeng_box_pic_box">
              <van-image width="0.8rem" height="0.8rem" :src="gift.actionProductIconUrl" />
              <div class="order_zeng_box_pic_num flex-r-c-c">
                x{{ gift.actionProductNum }}
              </div>
            </div>
          </div>
        </van-cell>
      </div>
      <!-- 订单组合商品区域 -->
      <!-- <div v-if="groupList.length > 0" class="order_zeng_box" @click="group_show = true">
        <van-cell>
          <template #title>
            <span class="custom-title">组合商品：</span>
          </template>
          <div v-for="(group,group_index) in groupList" :key="group_index" class="order_zeng_box_pic">
            <div class="order_zeng_box_pic_box">
              <van-image width="0.8rem" height="0.8rem" :src="group.actionProductIconUrl" />
              <div class="order_zeng_box_pic_num flex-r-c-c">
                x{{ group.actionProductNum }}
              </div>
            </div>
          </div>
        </van-cell>
      </div> -->
      <!-- 订单备注区域 -->
      <div class="order_remark_box">
        <van-cell-group>
          <van-field v-model="remark" label="备注:" placeholder="选填，请和商家协商一致" input-align="right" @input="inputremark" />
        </van-cell-group>
      </div>
    </div>

    <!-- 顺手带一件 -->
    <div class="order_recommend flex-c-s-s">
      <div class="cart_recommend_top flex-r-sb-s">
        <div class="flex-r-s-s">
          <span>随手购</span>
        </div>
        <div class="cart_recommend_top_change flex-r-c-c" style="margin-left:5.3rem" @click="changeOne">
          <van-icon name="replay" style="margin-right:0.25rem" />
          <span>换一组</span>
        </div>
      </div>
      <div class="cart_buOne_pro" name="list">
        <div v-for="(item_by,index_by) in proList" :key="index_by" class="flex-r-s-c">
          <div class="cart_buOne_pro_box flex-r-s-s">
            <div class="img">
              <van-image width="2.56rem" height="2.56rem" :src="item_by.saleIconUrl" />
            </div>
            <div class="msg flex-c-s-s">
              <div class="msg_name van-multi-ellipsis--l2">{{ item_by.saleName }}</div>
              <div class="msg_brand_model van-ellipsis">{{ item_by.proBrand }} | {{ item_by.proModel }}</div>
              <div class="money_cart flex-r-sb-c">
                <div class="money">
                  <span style="font-size:0.29rem">￥</span>{{ item_by.salePrice }}
                </div>
              </div>
            </div>
          </div>
          <van-checkbox v-model="item_by.checked" checked-color="#0767AB" icon-size="0.45rem"
                        @change="changeByOne(item_by)"
          />
        </div>
      </div>
    </div>

    <!-- 支付方式 -->
    <div class="order_payway_box ">
      <div class="payway_title">支付方式</div>
      <div class="payway_types">
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left  flex-r-s-c">
            <van-image width="0.48rem" height="0.48rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_way_01.png" />
            <div class="type_item_name">在线支付</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">微信支付</span>
            <van-checkbox v-model="payWay.wechat" checked-color="#0767AB" icon-size="0.45rem"
                          :disabled="order_info.onlyATL" @change="changePayWay"
            />
          </div>
        </div>
        <van-divider v-if="!order_info.onlyATL" />
        <div v-if="!order_info.onlyATL" class="payway_type_item flex-r-sb-c" style="margin-bottom:0.3rem">
          <!-- <div class="payway_type_item flex-r-sb-c" style="margin-bottom:0.3rem"> -->
          <div class="type_item_left  flex-r-s-c">
            <van-image width="0.48rem" height="0.48rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_way_02.png" />
            <div class="type_item_name">账期结算</div>
          </div>
          <div class="type_item_right  flex-r-s-c" @click="payBill">
            <span v-if="order_info.billCase" style="margin-right:0.21rem">{{ order_info.billCase.mouth }}个月 |
              {{ order_info.billCase.way }}
            </span>
            <span v-else style="margin-right:0.21rem">请选择账期方案</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <!-- 结算金额 -->
    <div class="order_check_box order_payway_box">
      <div class="check_title">结算金额</div>
      <div class="payway_types">
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">商品原价</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">￥{{ order_info.originPrice }}</span>
          </div>
        </div>
        <van-divider v-if="(order_info.confirmActionAmount*1)" />
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c" style="height:0.7rem" @click="openRule">
            <div class="check_item_name">商品运费 </div>
            <div>
              <van-icon name="question-o" style="margin-top:0rem" color="#999999" size="0.45rem" />
            </div>
          </div>
          <div v-if="(parseInt(type) === 4 || order_info.companies[0].companyId != '1000' || showPacket) && showPacket"
               class="type_item_right  flex-r-s-c"
          >
            <span
              style="margin-right:0.21rem;text-decoration:line-through;color: #ED0D0D;"
            >￥{{ order_info.originalExpressPrice }}</span>
            <span style="margin-right:0.21rem;">￥{{ order_info.expressPrice }}</span>
          </div>
          <div v-else class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;">￥{{ order_info.expressPrice }}</span>
          </div>
        </div>
        <van-divider v-if="(order_info.confirmActionAmount*1)" />
        <div v-if="(order_info.confirmActionAmount*1)" class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_minu.png" />
            <div class="check_item_name">活动满减</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;">￥{{ order_info.confirmActionAmount }}</span>
          </div>
        </div>
        <van-divider v-if="order_info.enableCoupons&&order_info.enableCoupons.length>0" />
        <div v-if="order_info.enableCoupons&&order_info.enableCoupons.length>0" class="payway_type_item flex-r-sb-c"
             style="margin-bottom:0.3rem"
        >
          <div class="type_item_left check_item_left flex-r-s-c">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_coupon.png" />
            <div class="check_item_name">优惠券</div>
          </div>
          <div class="type_item_right  flex-r-s-c" @click="payCoupon">
            <span v-if="couponItem"
                  style="margin-right:0.21rem;color:#ED0D0D;"
            >{{ couponItem.couponClass === 2 ? couponItem.discountNum+'折':'-￥'+couponItem.money }}
            </span>
            <span v-else style="margin-right:0.21rem">{{ order_info.enableCoupons.length }}张可用
            </span>
            <van-icon name="arrow" />
          </div>
        </div>
        <!-- vip -->
        <van-divider
          v-if=" order_info.formType !== 3 && order_info.formType !== 4 && parseInt(order_info.discount) < 100"
        />
        <div v-if="order_info.formType !== 3 &&order_info.formType !== 4 && parseInt(order_info.discount) < 100"
             class="payway_type_item flex-r-sb-c"
        >
          <div class="type_item_left check_item_left  flex-r-s-c" style="height:0.7rem">
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/vip/vip_sub_icon.png" />
            <div class="check_item_name">{{ order_info.mallVipLevelName }}享{{ order_info.discount }}折</div>

            <van-popover v-model="showPopover" theme="dark" placement="top" trigger="click">
              <div style="font-size: 0.35rem;color: #fff;padding: 0.2rem;">会员折扣权益，享满减用券价后折上折</div>
              <template #reference>
                <van-icon name="question-o" style="margin-top:0rem" color="#999999" size="0.45rem" />
              </template>
            </van-popover>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;">￥{{ vipMoney }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部提交bar -->
    <orderSub ref="orderSub" :retotal="retotal" :coupon="totalDiscount" @subOrder="openOrder" />
    <!-- 优惠券选择 -->
    <van-popup v-model="couponShow" round position="bottom" :style="{ height: '40%' }">
      <div class="order_coupon_list">
        <van-radio-group v-model="couponRadio">
          <van-radio v-for="(coupon,coupon_index) in order_info.enableCoupons" :key="coupon_index"
                     checked-color="#0767AB" :name="coupon.couponId" @click="chooseCoupon(coupon)"
          >
            <van-image v-if="coupon.mergeType === 0" width="8.93rem" height="2.29rem" :src="coupon.icon" />
            <div v-if="coupon.mergeType === 1" class="hecheng_coupon flex-r-s-c">
              <div class="pirce" style="color: #ED0D0D;">
                <span style="font-size: 0.37rem;">￥</span>{{ coupon.money }}
              </div>
              <div class="msg flex-c-s-s">
                <div style="font-size: 0.37rem;font-weight: 700;color: #333;">商城通用现金券</div>
                <div style="font-size: 0.32rem;color: #333;">满{{ coupon.atLeast }}可用</div>
              </div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="order_coupon_btn">
        <van-button round color="#0767AB" class="coupon_btn_cancer" @click="chooseCoupon(null)">不使用优惠券</van-button>
      </div>
    </van-popup>
    <!-- loading遮罩层 -->
    <van-overlay :show="overlayShow" @click="overlayShow = false">
      <div class="overlay_loading">
        <van-loading color="#eee" size="0.6rem" type="spinner" vertical>订单加载中...</van-loading>
      </div>
    </van-overlay>
    <!-- 资质认证弹窗 -->
    <van-dialog v-model="certificationShow" :show-confirm-button="false">
      <div class="certification_dialog flex-c-c-c">
        <van-image width="4.11rem" height="2.75rem" src="https://oss.xiaoyi120.com/shop2.0/home/certification.png" />
        <div class="certification_title"> 您尚未获得认证资格 </div>
        <div class="certification_tip"> 暂时无法采购 </div>
        <van-button class="certification_btn" round type="info" color="#0767ab" @click="toUpCertification">立即认证
        </van-button>
      </div>
    </van-dialog>

    <!-- vip 领取提示 -->
    <van-dialog v-model="vipGetDialog" :show-confirm-button="false">
      <div class="vipGetDialog_box flex-c-c-c">
        <van-icon class="vipGetDialog_close" name="close" @click="vipGetDialog = false" />
        <van-image class="vipGetDialog_btn" width="5.07rem" height="1.45rem"
                   src="https://oss.xiaoyi120.com/shop2.0/vip/vipGetDialog_btn.png" @click="getVipCard()"
        />
      </div>
    </van-dialog>
    <!-- vip 领取提示 -->
    <van-dialog v-model="vipGetDialog2" :show-confirm-button="false">
      <div class="vipGetDialog_box bg_2 flex-c-s-c">
        <van-icon class="vipGetDialog_close2" name="close" @click="vipGetDialog2 = false" />
        <div class="vipGetDialog_title">领卡再付款 享 <span style="color: #C2460E">{{ vipGetDialogdiscount }}</span> 折</div>
        <div class="vipGetDialog_msg">- 尊享5大会员权益 -</div>
        <div class="vipGetDialog_card_info">领取后，本单预计最高可省</div>
        <div class="vipGetDialog_card_money flex-r-s-c"> <span style="font-size: 0.48rem;">￥</span> {{ saved }}</div>
        <van-image class="vipGetDialog_btn2" width="5.57rem" height="1.95rem"
                   src="https://oss.xiaoyi120.com/shop2.0/vip/vipGetDialog_btn.png" @click="getVipCard()"
        />
      </div>
    </van-dialog>
    <!-- 运费tips -->
    <van-popup v-model="express_show" round position="bottom" closeable close-icon="close" :style="{ height: '16rem' }">
      <div class="popup_bg flex-c-c-c">
        <div class="popup_bg_title">商品运费规则</div>
        <div class="popup_bg_body flex-c-c-c">
          <div class="popup_bg_body_bg body1 flex-c-c-s">
            <div class="title">一、运费规则</div>
            <div class="msg">订单满足以下情况，可享受包邮服务，免邮费哦。</div>
            <div class="msg">
              <van-image width="8.93rem" height="5.87rem"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/express_table03.png"
              />
            </div>
            <div class="msg" style="color: #d2541c;margin-top: 0.21rem">*不满足则需支付10元/单运费。</div>
          </div>
          <div class="popup_bg_body_bg body2 flex-c-c-s">
            <div class="title">二、默认顺丰快递</div>
            <div class="msg">如有特殊需求，请联系客服。</div>
          </div>
          <div class="popup_bg_body_bg body1 flex-c-c-s">
            <div class="title">三、退运费规则</div>
            <div class="msg">若出现用户在较短时间内连续下单，仓库合并<span style="color: #d2541c;">多笔订单为一个包裏发货</span>"的情况，系统将在合并订单后，自动将<span
              style="color: #d2541c;"
            >多收的运费</span>按<span
              style="color: #d2541c;"
            >原路退回</span>至支付账户，请注意查收；如有疑问，请联系客服。</div>
          </div>
        </div>

        <!-- <van-image width="9.25rem" height="10rem" src="https://oss.xiaoyi120.com/shop2.0/order/express_rule_msg3.png" /> -->
      </div>
    </van-popup>
    <!-- 赠品明细 -->
    <van-popup v-model="gift_show" round position="bottom" closeable close-icon="close" :style="{ minHeight: '25%' }">
      <div class="popup_gift flex-c-s-s">
        <div class="popup_gift_title">赠品明细</div>
        <div class="popup_gift_box flex-c-s-c">
          <div v-for="(gift,gift_index) in giftList" :key="gift_index">
            <div class="popup_gift_box_pro flex-r-s-s">
              <van-image width="2.4rem" height="2.4rem" :src="gift.actionProductIconUrl" />
              <div class="popup_gift_box_pro_info flex-c-s-s">
                <div class="name van-multi-ellipsis--l2">{{ gift.actionProductName }}</div>
                <div class="brandModel van-ellipsis ">{{ gift.actionProductBrand }} | {{ gift.actionProductSpec }}</div>
                <div class="priceNum flex-r-sb-c">
                  <div class="price"><span style="font-size:0.32rem">￥</span>0.00</div>
                  <div class="num">x{{ gift.actionProductNum }}</div>
                </div>
              </div>
            </div>
            <van-divider />
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 组合商品明细 -->
    <van-popup v-model="group_show" round position="bottom" closeable close-icon="close" :style="{ minHeight: '25%' }">
      <div class="popup_gift flex-c-s-s">
        <div class="popup_gift_title">组合商品明细</div>
        <div class="popup_gift_box flex-c-s-c">
          <div v-for="(group,group_index) in groupList" :key="group_index">
            <div class="popup_gift_box_pro flex-r-s-s">
              <van-image width="2.4rem" height="2.4rem" :src="group.actionProductIconUrl" />
              <div class="popup_gift_box_pro_info flex-c-s-s">
                <div class="name van-multi-ellipsis--l2">{{ group.actionProductName }}</div>
                <div class="brandModel van-ellipsis ">{{ group.actionProductBrand }} | {{ group.actionProductSpec }}
                </div>
                <div class="priceNum flex-r-sb-c">
                  <div class="price"><span style="font-size:0.32rem">￥</span>0.00</div>
                  <div class="num">x{{ group.actionProductNum }}</div>
                </div>
              </div>
            </div>
            <van-divider />
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 如遇缺货 货单 -->
    <van-popup v-model="acticon_show" round position="bottom" closeable close-icon="close"
               :style="{ minHeight: '25%' }"
    >
      <div class="flex-c-s-s">
        <!-- 是否需要货单区域 -->
        <div v-if="userIdentityFlag" class="order_trank_box" style="padding-bottom: 0;">
          <div class="order_trank_box_title">是否需要货单</div>
          <div class="order_trank_box_choose flex-r-sa-c">
            <div :class="isNeed === 1 ? 'is_need_trank is_need_trank_actived':'is_need_trank'" @click="isNeedTrank(1)">
              <van-image v-if="isNeed === 1" width="0.42rem" height="0.42rem" class="is_need_trank_img"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/choosed_right.png"
              />
              <div>需要货单</div>
            </div>
            <div :class="isNeed === 0 ? 'is_need_trank is_need_trank_actived':'is_need_trank'" @click="isNeedTrank(0)">
              <van-image v-if="isNeed === 0" width="0.42rem" height="0.42rem" class="is_need_trank_img"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/choosed_right.png"
              />
              <div>不要货单</div>
            </div>
          </div>
        </div>
        <!-- 如遇缺货 -->
        <div class="order_trank_box">
          <div class="order_trank_box_title flex-r-s-c">如遇缺货
            <span style="font-size: 0.3rem;color: #E33A36;margin-left: 0.21rem;font-weight: 400;">*7-14天补发，未发立赠现金券</span>
          </div>
          <div class="order_trank_box_choose flex-r-sa-c">
            <div :class="nopro === 1 ? 'is_need_trank is_need_trank_actived':'is_need_trank'" style="width: 2.67rem;"
                 @click="isNeedpro(1)"
            >
              <van-image v-if="nopro === 1" width="0.42rem" height="0.42rem" class="is_need_trank_img"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/choosed_right.png"
              />
              <div>到齐再发</div>
            </div>
            <div :class="nopro === 2 ? 'is_need_trank is_need_trank_actived':'is_need_trank'" style="width: 2.67rem;"
                 @click="isNeedpro(2)"
            >
              <van-image v-if="nopro === 2" width="0.42rem" height="0.42rem" class="is_need_trank_img"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/choosed_right.png"
              />
              <div>先发有货</div>
            </div>
            <div :class="nopro === 3 ? 'is_need_trank is_need_trank_actived':'is_need_trank'" style="width: 2.67rem;"
                 @click="isNeedpro(3)"
            >
              <van-image v-if="nopro === 3" width="0.42rem" height="0.42rem" class="is_need_trank_img"
                         src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/choosed_right.png"
              />
              <div>与我联系</div>
            </div>
          </div>
        </div>
        <div class="subOrderButton" @click="subOrder"> 确认 </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import './../cart/index.scss'
import orderSub from '@/components/bar/order_sub'
import proCard04 from '@/components/pro_card_04_suborder'
import { mallOrder_confirmOrderUnion, submitOrderUnion, payStudy, findAddress, confirmExpressPrice } from '@/api/order'
import { vipOrderPopUpResDto, receiveAutomatic } from '@/api/vip'
import { user_certificationDecide } from '@/api/user'
import { productModule_productList } from '@/api/home'
export default {
  name: 'SubOrder',
  components: { proCard04, orderSub },
  data() {
    return {
      certificationShow: false, // 资质认证
      certificationDecide: null,
      couponShow: false,
      overlayShow: false,
      pid: '',
      pnum: '',
      cid: '1000',
      type: '',
      item_info: [],
      order_info: {},
      shopinglistId: '',
      remark: '',
      payWay: { wechat: true },
      couponRadio: '',
      couponItem: null,
      totalDiscount: null,
      retotal: 0,
      vipMoney: 0,
      isNeed: 1,
      nopro: 1,

      isBillShow: 0,
      timeLimitActivityType: false,
      showPopover: false,
      vipGetDialog: false,
      vipGetDialog2: false,
      vipGetDialogdiscount: '',
      saved: '',
      showPacket: false,
      userIdentityFlag: false,
      express_show: false,

      gift_show: false,
      giftList: [],

      group_show: false,
      groupList: [],

      couponNow: null,
      couponAddress: null,
      flag: false,

      acticon_show: false,

      // 商品列表 顺手带一件
      proSearch: { page: 1, limit: 2, mallProductModuleId: '1782335384953503744' },
      proList: [],
      checkProIDs: []
    }
  },
  created() {
    if (sessionStorage.getItem('timeLimitActivityType')) { this.timeLimitActivityType = true }
    this.splitUrlData(this.$route.query)
    this.getInfo()
    this.getProList()
    this.overlayShow = true
  },
  methods: {
    backPath() { window.history.back() },
    // 用户是否领取vip
    getVipDialog() {
      vipOrderPopUpResDto({ confirmPrice: this.retotal, orderCategory: 1 }).then(res => {
        if (res.data.popUpStatus === 1) {
          if (parseInt(res.data.discount) === 100 || parseInt(res.data.saved) < 5) {
            this.vipGetDialog = true
          } else {
            this.vipGetDialog2 = true
            this.vipGetDialogdiscount = res.data.discount
            this.saved = res.data.saved
          }
        }
      })
    },
    // 领取
    getVipCard() {
      receiveAutomatic().then(res => {
        this.vipGetDialog = false
        this.vipGetDialog2 = false
        this.$toast({ message: '领取成功！', duration: 1 * 1000 })
        this.overlayShow = true
        this.getInfo()
      })
    },
    // 用户进入首页判断是否有资质
    getCertificationDecide() {
      user_certificationDecide().then(res => {
        this.certificationDecide = res.data.certificationDecide
        this.certificationShow = res.data.certificationDecide !== 1
      })
    },
    // 去认证
    toUpCertification() {
      if (this.certificationDecide === -1) { this.$router.push('/home/certification') }
      if (this.certificationDecide === 0) { this.$router.push('/publictips/success?type=1') }
      if (this.certificationDecide === 2) { this.$router.push('/publictips/success?type=2') }
    },
    // 切割数据
    splitUrlData(info) {
      console.log('info', info)
      this.item_info = []
      this.type = info.type
      if (info.item && parseInt(info.type) !== 1) { var item_list = info.item.split('-') }
      console.log('item_list', item_list)
      if (parseInt(info.type) === 3 || parseInt(info.type) === 5) {
        console.log('info.type', info.type)
        this.shopinglistId = info.shopinglistId
        this.item_info.push({ id: this.shopinglistId, buyNumber: 0, timeLimitActivityType: '' })
        console.log('this.order_info', this.order_info)
        console.log('this.item_info', this.item_info)
      } else if (parseInt(info.type) === 1) {
        console.log('info.type', info.type)
        var cart_item_list = info.item.split(',')
        for (const i in cart_item_list) {
          const cartId = cart_item_list[i].split('-')[0]
          this.item_info.push({ id: cartId, buyNumber: 0, timeLimitActivityType: '' })
        }
      } else if (parseInt(info.type) === 4) {
        console.log('info.type', info.type)
        var cart_item_list4 = info.item.split(',')
        for (const i in cart_item_list4) {
          const cartId = cart_item_list4[i].split('-')[0]
          this.item_info.push({ id: cartId, buyNumber: 1, timeLimitActivityType: '4' })
        }
      } else {
        console.log('info.type', info.type)
        var buyNumber = 0
        if (sessionStorage.getItem('orderProNum')) {
          buyNumber = parseInt(sessionStorage.getItem('orderProNum'))
        } else {
          buyNumber = item_list[1]
        }
        this.item_info.push({
          id: item_list[0], buyNumber: buyNumber,
          timeLimitActivityType: this.timeLimitActivityType ? 3 : ''
        })
        this.pid = item_list[0]
        this.pnum = buyNumber
      }
    },
    // 获取订单内容
    getInfo(flag) {
      this.flag = flag || false
      console.log('getInfo 0', this.checkProIDs)
      console.log('productModuleProductIdList', this.checkProIDs)
      // if (sessionStorage.getItem('productModuleProductIdList')) {
      //   this.checkProIDs = JSON.parse(sessionStorage.getItem('productModuleProductIdList'))
      // }
      const parms = {
        confirmType: this.type,
        companyId: '1000',
        confirmItems: this.item_info,
        productModuleProductIdList: this.checkProIDs,
        couponId: flag ? this.couponNow.couponId : ''
      }
      mallOrder_confirmOrderUnion(parms).then(res => {
        this.order_info = res.data
        // 判断是否只有冷链商品
        // 地址的获取
        if (sessionStorage.getItem('address')) {
          this.order_info.address = JSON.parse(sessionStorage.getItem('address'))
          // 订单运费 金额获取
          const express_prams = {
            coldOriginPrice: res.data.coldOriginPrice, commonOriginPrice: res.data.commonOriginPrice, confirmActionAmount: res.data.confirmActionAmount,
            confirmOrderId: res.data.confirmOrderId, confirmType: this.type, couponId: this.couponNow ? this.couponNow.couponId : '', discount: res.data.discount, originPrice: res.data.originPrice,
            userReceiptAddressId: this.order_info.address.id
          }
          confirmExpressPrice(express_prams).then(res_express => {
            this.$set(this, 'retotal', res_express.data.confirmPrice)
            this.$set(this, 'vipMoney', res_express.data.vipPrice)
            this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
            this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
            this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
            var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
            console.log('totalDiscount', totalDiscount)
            this.$set(this, 'totalDiscount', totalDiscount.toFixed(2))
            this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
          })
        } else {
          findAddress({ productModuleFlag: 0, productModuleId: '' }).then(res_address => {
            const parms = {
              id: res_address.data.userReceiptAddressId,
              name: res_address.data.receiptPersonName,
              tel: res_address.data.receiptMobile,
              address: res_address.data.province ? res_address.data.province.provinceName + res_address.data.city.cityName + res_address.data.area.areaName +
                res_address.data.receiptAddress : null
            }
            this.$set(this.order_info, 'address', parms)
            // 订单运费 金额获取
            const express_prams = {
              coldOriginPrice: res.data.coldOriginPrice, commonOriginPrice: res.data.commonOriginPrice, confirmActionAmount: res.data.confirmActionAmount,
              confirmOrderId: res.data.confirmOrderId, confirmType: this.type, couponId: this.couponNow ? this.couponNow.couponId : '', discount: res.data.discount, originPrice: res.data.originPrice,
              userReceiptAddressId: res_address.data.userReceiptAddressId
            }
            confirmExpressPrice(express_prams).then(res_express => {
              this.$set(this, 'retotal', res_express.data.confirmPrice)
              this.$set(this, 'vipMoney', res_express.data.vipPrice)
              this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
              this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
              this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
              var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
              console.log('totalDiscount', totalDiscount)
              this.$set(this, 'totalDiscount', totalDiscount.toFixed(2))
              this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
            })
          })
        }
        // 代发用户的判断
        this.userIdentityFlag = parseInt(res.data.userIdentity) === 1
        // 备注的获取
        if (sessionStorage.getItem('orderRemark')) { this.remark = sessionStorage.getItem('orderRemark') }
        // 账期的选择
        if (sessionStorage.getItem('billCase')) {
          this.order_info.billCase = JSON.parse(sessionStorage.getItem('billCase'))
          this.payWay.wechat = false
        } else {
          this.payWay.wechat = true
        }
        // 赠品的获取 组合商品的获取
        this.giftList = []
        this.groupList = []
        if (res.data.companies[0].promotionActivityDtoList) {
          res.data.companies[0].promotionActivityDtoList.forEach(item => {
            if (item.actionType === 'ZS_SKU_NUM_AC') this.giftList = this.giftList.concat(item.actionSkuDtos)
            if (item.actionType === 'ZH_NUM_AC') this.groupList = this.groupList.concat(item.actionSkuDtos)
          })
        }

        this.overlayShow = false
        this.getVipDialog()
      })
    },
    // 顺手带一件
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      productModule_productList(this.proSearch).then(res => {
        this.$toast.clear()
        if (res.data.list.length > 0) {
          for (const i in res.data.list) { this.$set(res.data.list[i], 'checked', false) }
          this.proList = res.data.list
        } else {
          this.proSearch.page = 1
          this.getProList()
        }
      })
    },
    changeOne() {
      this.proSearch.page = this.proSearch.page * 1 + 1
      this.checkProIDs = []
      this.getProList()
    },
    changeByOne(e) {
      console.log('changeByOne', e)
      if (e.checked) {
        this.checkProIDs.push(e.productId)
      } else {
        for (const i in this.checkProIDs) {
          if (this.checkProIDs[i] === e.productId) {
            this.checkProIDs.splice(i, 1)
          }
        }
      }
      console.log('getInfo 1', this.checkProIDs)
      this.$nextTick(() => { this.getInfo() })
    },
    // 修改地址
    changeAddress(addressId) {
      var flag = this.userIdentityFlag ? 1 : 2
      console.log(addressId)
      // sessionStorage.setItem('productModuleProductIdList', JSON.stringify(this.checkProIDs))
      this.$router.push('/user/addressList?aId=' + addressId + '&flag=' + flag)
    },
    // 修改备注
    inputremark(e) { sessionStorage.setItem('orderRemark', e) },
    // 货单是否需要
    isNeedTrank(item) { this.isNeed = item },
    // 如遇缺货
    isNeedpro(item) { this.nopro = item },
    // 修改商品数量
    changeNum(num) {
      this.chooseCoupon(null)
      sessionStorage.setItem('orderProNum', num)
      this.overlayShow = true
      const info = {
        item: this.pid + '-' + num + '-' + this.cid,
        type: this.type
      }
      this.splitUrlData(info)
      this.$nextTick(() => { this.getInfo() })
    },
    openRule() { this.express_show = true },
    // 选择微信支付
    changePayWay(e) {
      if (e) {
        sessionStorage.removeItem('billCase')
        this.$set(this.order_info, 'billCase', null)
        this.$forceUpdate()
      }
    },
    // 账期选择
    payBill() {
      this.$router.push('/order/billPay?price=' + this.order_info.originPrice + '&flag=3')
    },
    // 优惠券选择
    payCoupon() { this.couponShow = true },
    // 选中优惠券
    chooseCoupon(coupon) {
      console.log('coupon', coupon)
      this.couponNow = coupon
      this.couponItem = coupon
      var couponId = ''
      if (coupon) { couponId = coupon.couponId } else { this.couponRadio = '' }
      if (coupon && coupon.couponClass === 2) {
        this.overlayShow = true
        this.$refs.proCard04[0].changeflag(true)
        this.$nextTick(() => { this.getInfo(true) })
      } else {
        this.$refs.proCard04[0].changeflag(false)
        // 订单运费 金额获取
        const express_prams = {
          coldOriginPrice: this.order_info.coldOriginPrice, commonOriginPrice: this.order_info.commonOriginPrice, confirmActionAmount: this.order_info.confirmActionAmount,
          confirmOrderId: this.order_info.confirmOrderId, confirmType: this.type, couponId: couponId, discount: this.order_info.discount, originPrice: this.order_info.originPrice,
          userReceiptAddressId: this.order_info.address.id
        }
        confirmExpressPrice(express_prams).then(res_express => {
          this.$set(this, 'retotal', res_express.data.confirmPrice)
          this.$set(this, 'vipMoney', res_express.data.vipPrice)
          this.$set(this.order_info, 'originalExpressPrice', res_express.data.originalExpressPrice)
          this.$set(this.order_info, 'expressPrice', res_express.data.expressPrice)
          this.$set(this, 'showPacket', res_express.data.originalExpressPrice * 1 > 0)
          var totalDiscount = res_express.data.vipPrice * 1 + res_express.data.confirmActionAmount * 1 + res_express.data.couponPrice * 1
          console.log('totalDiscount', totalDiscount)
          this.$set(this, 'totalDiscount', totalDiscount.toFixed(2))
          this.$set(this.order_info, 'totalDiscount', totalDiscount.toFixed(2))
        })
        this.$nextTick(() => { this.getInfo(false) })
      }
      this.couponShow = false
    },
    // 提交订单
    openOrder() {
      this.acticon_show = true
    },
    subOrder() {
      console.log('this.order_info', this.order_info)
      this.$toast.loading({ message: '订单提交中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      var list = this.order_info.companies
      var companies = []
      var remarks = ''
      var msg = ''
      if (this.nopro === 1) msg = '发货备注>>>【缺货:到齐再发】>>>'
      if (this.nopro === 2) msg = '发货备注>>>【缺货:先发有货】>>>'
      if (this.nopro === 3) msg = '发货备注>>>【缺货:与我联系】>>>'
      remarks = msg + this.remark
      for (const i in list) {
        var company = {
          companyId: list[i].companyId,
          quoteStagingKey: this.order_info.billCase ? this.order_info.billCase.quoteStagingKey : '',
          shoppinglistId: this.shopinglistId, // 货单id
          products: [],
          remark: remarks
        }
        for (const j in list[i].products) {
          const pro = {
            buyNumber: list[i].products[j].proNumber,
            productId: list[i].products[j].productId,
            timeLimitActivityType: this.timeLimitActivityType ? 3 : ''
          }
          company.products.push(pro)
        }
        companies.push(company)
      }

      const parms = {
        orderCategory: this.payWay.wechat ? 1 : 3,
        userReceiptAddressId: this.order_info.address.id,
        confirmType: this.type,
        couponId: this.couponItem ? this.couponItem.couponId : '',
        typeNo: this.payWay.wechat ? '1' : '3',
        followPdfPrint: this.isNeed,
        companies: companies,
        productModuleProductIdList: this.checkProIDs

      }
      if (!this.userIdentityFlag) { parms.followPdfPrint = 0 }
      console.log('parms', parms)
      submitOrderUnion(parms).then(res => {
        if (this.payWay.wechat) {
          this.$set(res.data, 'type', 1)
          // 调起支付
          payStudy(res.data)
        } else {
          this.$router.push('/order/payResult?orderId=' + res.data.orderId + '&status=99')
          this.$toast.clear()
        }
        sessionStorage.removeItem('orderRemark')
        sessionStorage.removeItem('billCase')
        sessionStorage.removeItem('timeLimitActivityType')
        sessionStorage.removeItem('orderProNum')
      })
      // .catch(() => {
      //   sessionStorage.removeItem('billCase')
      //   sessionStorage.removeItem('timeLimitActivityType')
      // })
    }
  }
}
</script>

<style>
</style>
